import React from 'react';
import Layout from '../templates/Layout';

const HomePage = () => {
    return (
        <Layout
            title="Designsystemet"
            introtext="Felles retningslinjer og komponenter som hjelper oss med å lage helhetlige, brukervennlige, inkluderende løsninger for kundene våre"
            illustration="collaboration.svg"
            isFrontpage={true}
        >
            <div className="sb1ds-help">
                <h2 className="ffe-h3">Bli med!</h2>
                <p className="ffe-body-text">
                    Spørsmål om designsystemet, forslag til forbedringer eller noe annet på hjertet?
                </p>
                <ul className="ffe-bullet-list">
                    <li className="ffe-bullet-list__item">
                        <a className="ffe-link-text" href="https://github.com/SpareBank1/designsystem/issues">Legg inn et issue på GitHub</a>
                    </li>
                    <li className="ffe-bullet-list__item">
                        <a className="ffe-link-text" href="mailto:designsystem@sparebank1.no">Send oss en e-post</a>
                    </li>
                    <li className="ffe-bullet-list__item">
                        Si hei i <strong>#ext-designsystem</strong> på Slack
                    </li>
                </ul>
            </div>
        </Layout>
    );
};

export default HomePage;
